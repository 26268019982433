import { Api } from './../config';
import createCookie from './createCookie';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

export function checkTokenExpiry({ interval = 300, redirectUrl }) {
  let token = Cookies.get('CannToken');
  if (!token) window.location.href = redirectUrl;
  Api.defaults.headers = {
    common: { Authorization: `bearer ${token}` },
    'Content-Type': 'application/json',
  };
  let jwt = jwtDecode(token);
  if (!jwt || jwt.exp - interval < Math.floor(Date.now() / 1000)) {
    window.location.href = redirectUrl;
  }
  setInterval(() => {
    token = Cookies.get('CannToken');
    if (!token) window.location.href = redirectUrl;
    Api.defaults.headers = {
      common: { Authorization: `bearer ${token}` },
      'Content-Type': 'application/json',
    };
    jwt = jwtDecode(token);
    if (!jwt || jwt.exp - interval < Math.floor(Date.now() / 1000)) {
      window.location.href = redirectUrl;
    }
    if (jwt && jwt.exp - (interval * 288) < Math.floor(Date.now() / 1000)) {
      refreshToken();
    }
  }, interval * 1000);
}

function refreshToken() {
  Api.get('/user/refresh')
    .then(({ status, data }) => {
      if (status === 200 && data.token) {
        createCookie('CannToken', data.token, 2);
        Api.defaults.headers = {
          common: { Authorization: `bearer ${data.token}` },
          'Content-Type': 'application/json',
        };
      }
    })
    .catch((error) => {
      throw Error(error);
    });
}

export default checkTokenExpiry;
