import styled, { injectGlobal } from 'styled-components';
import fonts from 'theme/fonts';
import colors from 'theme/colors';

/* eslint no-unused-expressions: 0 */
// This can't be easily tested so we ignore it in package.json.jest (maybe int he future we could check the dom for <style> via JSDOM)
injectGlobal`

  html, body {
    min-height: 100%;
    min-width: 100%;
    overscroll-behavior: none;
  }

  body {
    ${fonts.misc}
    overflow-x: hidden;
    color: ${colors.ivpText};
  }

  ::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
  }

  #app {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
    height: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${fonts.heading}
  }

  p, label {
    ${fonts.body}
  }

  strong, button {
    ${fonts.bold}
  }

  .text-disabled {
    color: ${colors.gray};
  }

  a {
    color: ${colors.igRed};

  }
  @media print {
    #app {
      min-width: 1024px;
    }
  }
`;

export const Site = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    position: relative;
  }
`;

export const Container = styled.div`
  display: block;
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  user-select: none;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: auto;
    padding-bottom: 114px;
  }
`;

export const Page = styled.div``;

export const Wrapper = styled.div`
  margin: 35px auto;
  width: 100%;
  max-width: 1200px;
  padding: 0 35px;
`;
