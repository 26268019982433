export function getParameterByName(name, url) {
  let currentUrl = '';
  if (!url) {
    currentUrl = window.location.href;
  } else currentUrl = url;
  const escapedName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${escapedName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(currentUrl);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default getParameterByName;
