/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyRouterMiddleware, Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { useScroll } from 'react-router-scroll';

// Import utility functions
import { getParameterByName, createCookie, checkTokenExpiry } from 'utils';

import { cookie, jwt, loginRoute } from 'config';

// Import reset css
import 'sanitize.css/sanitize.css';

// Import Project Assets
import './assets/css/font-awesome.min.css';
import './assets/css/bclfonts.css';
import './assets/css/cannabisfonts.css';

// Import root app
import App from 'containers/App';

// Import selector for `syncHistoryWithStore`
import { makeSelectLocationState } from 'containers/App/selectors';

import configureStore from './store';

// Import CSS reset and Global Styles
import './theme/global-styles';

// Import root routes
import createRoutes from './routes';

const isDev = window.location.hostname === 'localhost';

// Import & Config Raven
// import Raven from 'raven-js';

// if (!isDev) {
//   Raven.config().install();
// }

const token = getParameterByName('token');

if (token) {
  createCookie('CannToken', token, 2);
  if (window.history.pushState) {
    window.history.pushState(
      {},
      document.title,
      `${window.location.href.substring(0, window.location.href.indexOf('?'))}`,
    );
  }
}

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`
const initialState = {};
export const store = configureStore(initialState, browserHistory);

// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: makeSelectLocationState(),
});

// Set up the router, wrapping all Routes in the App component
const rootRoute = {
  component: App,
  childRoutes: createRoutes(store),
};

checkTokenExpiry({ interval: 300, redirectUrl: loginRoute });

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Router
        history={history}
        routes={rootRoute}
        render={
          // Scroll to top when going to a new page, imitating default browser
          // behaviour
          applyRouterMiddleware(useScroll())
        }
      />
    </Provider>,
    document.getElementById('app'),
  );
};
if (cookie && jwt.exp > Math.floor(Date.now() / 1000)) {
  if (module.hot) {
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept(() => {
      render();
    });
  }
  render();
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (process.env.NODE_ENV === 'production') {
//   require('offline-plugin/runtime').install(); // eslint-disable-line global-require
// }
