export const SET_DETAIL_PRODUCT = 'shop/SET_DETAIL_PRODUCT';
export const SET_COMPARE_PRODUCTS = 'shop/SET_COMPARE_PRODUCTS';
export const SET_PRODUCT_FILTERS = 'shop/SET_PRODUCT_FILTERS';
export const SET_SCREEN_HISTORY = 'shop/SET_SCREEN_HISTORY';
export const SET_CART_PRODUCTS = 'shop/SET_CART_PRODUCTS';
export const SET_CATALOGUE_CURSOR = 'shop/SET_CATALOGUE_CURSOR';
export const RESET_SESSION = 'shop/RESET_SESSION';
export const SET_STORE_ID = 'shop/SET_STORE_ID';
export const GET_FILTERS = 'shop/GET_FILTERS';
export const GET_FILTERS_REJECTED = 'shop/GET_FILTERS_REJECTED';
export const GET_FILTERS_FULFILLED = 'shop/GET_FILTERS_FULFILLED';
export const GET_FILTERED_PRODUCTS = 'shop/GET_FILTERED_PRODUCTS';
export const GET_FILTERED_PRODUCTS_PENDING = 'shop/GET_FILTERED_PRODUCTS_PENDING';
export const GET_FILTERED_PRODUCTS_REJECTED = 'shop/GET_FILTERED_PRODUCTS_REJECTED';
export const GET_FILTERED_PRODUCTS_FULFILLED = 'shop/GET_FILTERED_PRODUCTS_FULFILLED';
export const SET_FILTERED_PRODUCTS = 'shop/SET_FILTERED_PRODUCTS';
export const SET_FILTERED_PRODUCTS_POLLING = 'shop/SET_FILTERED_PRODUCTS_POLLING';
export const RESET_FILTERED_PRODUCTS = 'shop/RESET_FILTERED_PRODUCTS';
export const GET_SEARCH_PRODUCTS_PENDING = 'shop/GET_SEARCH_PRODUCTS_PENDING';
export const GET_APP_CONFIG = 'shop/GET_APP_CONFIG';
export const GET_APP_CONFIG_FULFILLED = 'shop/GET_APP_CONFIG_FULFILLED';
export const GET_STORE_CONFIG = 'shop/GET_STORE_CONFIG';
export const GET_STORE_CONFIG_FULFILLED = 'shop/GET_STORE_CONFIG_FULFILLED';
export const GET_NAV_CONFIG = 'shop/GET_NAV_CONFIG';
export const GET_NAV_CONFIG_FULFILLED = 'shop/GET_NAV_CONFIG_FULFILLED';
export const REFRESH_FILTERED_PRODUCTS_POLLING = 'shop/REFRESH_FILTERED_PRODUCTS_POLLING';
export const SET_MODAL_STATUS = 'shop/SET_MODAL_STATUS';
export const SET_SEARCH_INPUT = 'shop/SET_SEARCH_INPUT';
export const SET_MENU_ITEM = 'shop/SET_MENU_ITEM';
export const SET_ORDER_NUMBER = 'shop/SET_ORDER_NUMBER';
export const SET_VIEW = 'shop/SET_VIEW';
export const SET_SORT = 'shop/SET_SORT';
export const SET_PRODUCTS_PAGINATION = 'shop/SET_PRODUCTS_PAGINATION';
export const POST_ORDER = 'shop/POST_ORDER';
export const SET_SHOW_SEARCH_PRODUCTS = 'shop/SET_SHOW_SEARCH_PRODUCTS';
export const SET_SEARCH_PRODUCTS = 'shop/SET_SEARCH_PRODUCTS';
export const SET_SEARCH_TIMESTAMP = 'shop/SET_SEARCH_TIMESTAMP';
export const RESET_SEARCH_PRODUCTS = 'shop/RESET_SEARCH_PRODUCTS';
export const SET_ERROR_MESSAGE = 'shop/SET_ERROR_MESSAGE';
export const SET_KEYBOARD = 'shop/SET_KEYBOARD';

// Welcome Screen Constants
export const GET_PRODUCTS = 'shop/GET_PRODUCTS';
export const CLEAR_RECENTLY_ADDED_PRODUCTS = 'shop/CLEAR_RECENTLY_ADDED_PRODUCTS';
