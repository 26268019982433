import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

function getUrlBase() {
  // if (window.location.href.indexOf('localhost') >= 0) {
  //   return 'http://localhost:4040';
  // }

  // return '/rest';
  return '';
}

function getCraftUrlBase() {
  if (window.location.href.indexOf('localhost') >= 0 || window.location.href.indexOf('test') >= 0) {
    // Stage URL
    return 'https://pricedisplaycms.test.bccannabisstores.com';
  }
  // Production URL
  return 'https://pricedisplaycms.bccannabisstores.com';
}

export const basePath = getUrlBase();
export const apiBasePath = getUrlBase();
export const restDomain = `${apiBasePath}/api`;
export const craftDomain = `${getCraftUrlBase()}/api`;
export const scrollTime = 550; // 2 seconds
export const refreshInventoryTime = 300000; // 5 minutes
export const refreshProductTime = 900000; // 15 minutes
export const refreshConfigTime = 1800000; // 30 minutes
export const checkVersionTime = 1800000; // 30 minutes
export const searchDebounceTime = 800; // a bit less than a second
export const liveDate = new Date('2018-09-15T09:30:00');
export const searchResultsDropdownLimit = 5;

export const gaTrackingCode = 'UA-108071308-1';

export const Api = axios.create({
  baseURL: restDomain,
  headers: {
    common: { Authorization: `bearer ${Cookies.get('CannToken')}` },
    'Content-Type': 'application/json',
  },
  params: {
    api_key: '',
  },
});

export const CraftApi = axios.create({
  baseURL: craftDomain,
  method: 'POST',
  headers: {
    common: { Authorization: '' },
    'Content-Type': 'application/json',
  },
});

export const cookie = Cookies.get('CannToken');
export const jwt = cookie && jwtDecode(cookie);
export const loginRoute = '/admin';
