export function filterProducts(products, inventory) {
  return products.map((item) => {
    const { subskus } = item;
    let outSubs = 0;
    const newSub = subskus.map((i) => {
      const _ = inventory.find((y) => i.subsku === y.sku);
      const inv = _ ? parseInt(_.available, 10) : 0;

      if (inv === 0) outSubs += 1;

      return ({
        ...i,
        inventory: inv,
      });
    });

    if (newSub.length === outSubs) return undefined;

    return { ...item, subskus: newSub };
  }).filter((i) => i);
}

export function getStatus(quantity, statusArr = ['out of stock', 'limited quantity', 'stocked']) {
  // eslint-disable-next-line no-param-reassign
  if (statusArr === undefined || statusArr.length !== 3) statusArr = ['out of stock', 'limited quantity', 'stocked'];
  if (quantity === undefined) return 'out of stock';

  if (Array.isArray(quantity)) {
    if (quantity.reduce((t, q) => t + q) === 0) return statusArr[0];
    if (quantity.some((q) => q <= 5)) return statusArr[1];
  }

  if (quantity < 1) return statusArr[0];
  if (quantity <= 5) return statusArr[1];

  return statusArr[2];
}
