/* global */
// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
// See http://blog.mxstbr.com/2016/01/react-apps-with-pages for more information
// about the code splitting business

import { getAsyncInjectors } from 'utils/asyncInjectors';
const pathRoot = '';

const errorLoading = (err) => {
  console.error('Dynamic page loading failed', err); // eslint-disable-line no-console
};

const loadModule = (cb) => (componentModule) => {
  cb(null, componentModule.default);
};

export default function createRoutes(store) {
  // Create reusable async injectors using getAsyncInjectors factory
  const { injectReducer, injectSagas } = getAsyncInjectors(store); // eslint-disable-line no-unused-vars
  return [
    {
      path: `${pathRoot}/`,
      name: 'homepage',
      getComponent(nextState, cb) {
        const importModules = Promise.all([import('containers/HomePage')]);

        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: `${pathRoot}/instore/:storeNumber/:screen`,
      name: 'instore',
      getComponent(nextState, cb) {
        const importModules = Promise.all([import('containers/InStoreScreen')]);

        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: `${pathRoot}/shop/:storeNumber`,
      name: 'shop',
      getComponent(nextState, cb) {
        const importModules = Promise.all([import('containers/ShopScreen/sagas'), import('containers/ShopScreen')]);

        const renderRoute = loadModule(cb);

        importModules.then(([sagas, component]) => {
          injectSagas(sagas.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: `${pathRoot}/adminOrder/:storeNumber`,
      name: 'shop',
      getComponent(nextState, cb) {
        const importModules = Promise.all([import('containers/AdminOrderScreen')]);

        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      // This MUST be the last object in array
      path: `${pathRoot}/*`,
      name: 'notfound',
      getComponent(nextState, cb) {
        import('containers/NotFoundPage').then(loadModule(cb)).catch(errorLoading);
      },
    },
  ];
}
