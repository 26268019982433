import { fromJS, OrderedMap } from 'immutable';

import {
  SET_COMPARE_PRODUCTS,
  SET_CART_PRODUCTS,
  CLEAR_RECENTLY_ADDED_PRODUCTS,
  SET_SCREEN_HISTORY,
  SET_CATALOGUE_CURSOR,
  RESET_SESSION,
  SET_DETAIL_PRODUCT,
  SET_MODAL_STATUS,
  SET_SEARCH_INPUT,
  SET_MENU_ITEM,
  GET_APP_CONFIG_FULFILLED,
  SET_ORDER_NUMBER,
  GET_STORE_CONFIG_FULFILLED,
  SET_VIEW,
  SET_SORT,
  POST_ORDER,
  SET_SHOW_SEARCH_PRODUCTS,
  SET_SEARCH_PRODUCTS,
  RESET_SEARCH_PRODUCTS,
  SET_ERROR_MESSAGE,
  SET_KEYBOARD,
  GET_PRODUCTS,
  GET_FILTERS_FULFILLED,
  GET_FILTERED_PRODUCTS_FULFILLED,
  GET_FILTERED_PRODUCTS_PENDING,
  SET_STORE_ID,
  SET_PRODUCTS_PAGINATION,
  RESET_FILTERED_PRODUCTS,
  SET_FILTERED_PRODUCTS_POLLING,
  GET_SEARCH_PRODUCTS_PENDING,
  SET_SEARCH_TIMESTAMP,
  SET_FILTERED_PRODUCTS,
} from './constants';

/* eslint-disable quote-props */
/* eslint-disable dot-notation */
const filterSection = {
  Brand: {
    label: 'Brand',
    isDynamic: true,
    isVariant: false,
    key: 'producer',
    multiSelect: true,
    options: [],
  },
  'CBD per selling unit': {
    label: 'CBD per selling unit',
    isDynamic: false,
    isVariant: false,
    staticFilter: [
      {
        key: 'cbdValues.unitOfMeasure',
        logic: '=',
        value: 'mg/g',
      },
    ],
    key: 'cbd',
    multiSelect: false,
    options: [
      {
        label: 'CBD less than 50mg/g',
        logic: '<=',
        value: 50,
      },
      {
        label: 'CBD less than 100mg/g',
        logic: '<=',
        value: 100,
      },
      {
        label: 'CBD less than 200mg/g',
        logic: '<=',
        value: 200,
      },
      {
        label: 'CBD less than 300mg/g',
        logic: '<=',
        value: 300,
      },
    ],
  },
  'THC per selling unit': {
    label: 'THC per selling unit',
    isDynamic: false,
    isVariant: false,
    staticFilter: [
      {
        key: 'thcValues.unitOfMeasure',
        logic: '=',
        value: 'mg/g',
      },
    ],
    key: 'thc',
    multiSelect: false,
    options: [
      {
        label: 'THC less than 50mg/g',
        logic: '<=',
        value: 50,
      },
      {
        label: 'THC less than 100mg/g',
        logic: '<=',
        value: 100,
      },
      {
        label: 'THC less than 200mg/g',
        logic: '<=',
        value: 200,
      },
      {
        label: 'THC less than 300mg/g',
        logic: '<=',
        value: 300,
      },
    ],
  },
  'Produced in': {
    label: 'Produced in',
    isDynamic: true,
    isVariant: false,
    key: 'region',
    multiSelect: true,
    options: [],
  },
  Type: {
    label: 'Type',
    isDynamic: true,
    isVariant: false,
    key: 'species',
    multiSelect: true,
    options: [],
  },
  'Carrier oil': {
    label: 'Carrier oil',
    isDynamic: true,
    isVariant: false,
    key: 'carrierOil',
    multiSelect: true,
    options: [],
  },
  'Consumption method': {
    label: 'Consumption method',
    isDynamic: true,
    isVariant: false,
    key: 'consumptionMethod',
    multiSelect: true,
    options: [],
  },
  Flavour: {
    label: 'Flavour',
    isDynamic: true,
    isVariant: false,
    key: 'flavours',
    multiSelect: true,
    options: [],
  },
  'Container size': {
    label: 'Container size',
    isDynamic: false,
    isVariant: true,
    key: 'containerSize',
    multiSelect: false,
    options: [
      {
        label: 'Size less than 250ml',
        logic: '<=',
        value: 250,
      },
      {
        label: 'Size less than 500ml',
        logic: '<=',
        value: 500,
      },
      {
        label: 'Size less than 1L',
        logic: '<=',
        value: 1000,
      },
      {
        label: 'Size less than 1.5L',
        logic: '<=',
        value: 1500,
      },
    ],
  },
  'Allergen information': {
    label: 'Allergen information',
    isDynamic: true,
    isVariant: false,
    key: 'allergen',
    multiSelect: true,
    options: [],
  },
  Terpenes: {
    label: 'Terpenes',
    isDynamic: true,
    isVariant: false,
    key: 'terpene',
    multiSelect: true,
    options: [],
  },
};

const productsPaginationLimit = 12;

/**
 * Concatenate two products lists and remove duplicates.
 * 
 * @param {Immutable.List} sourceList The source list
 * @param {Immutable.List} newList The new list to add
 * 
 * @returns {Immutable.List} The final list, with merged products and no duplicates.
 */
const mergeProducts = (sourceList, newList) =>
  sourceList
    .concat(newList)
    .reduce((map, product) => {
      const key = product.getIn(['_id', 'baseSku']);
      const dup = map.find((i) => i.getIn(['_id', 'baseSku']) === key);
      return dup ? map : map.set([key], product);
    }, new OrderedMap())
    .valueSeq()
    .toList();

// Initial State
const initialState = fromJS({
  storeName: '',
  storeID: '',
  products: {
    items: [],
    cache: {},
    isFetching: false,
    isPolling: false,
    pagination: {
      page: 1,
      limit: productsPaginationLimit,
      total: 1,
    },
  },
  filters: {},
  printerIpAddress: '',
  refreshOrdersInterval: 60,
  compareProducts: [],
  showError: false,
  showSearchProducts: false,
  searchProducts: {
    items: [],
    isFetching: false,
    hasFetched: false,
    timestamp: 0,
  },
  detailProduct: {},
  screenHistory: ['welcome'],
  cartProducts: [],
  recentlyAddedProducts: [],
  modalStatus: {},
  focusMenuItem: '',
  searchInput: '',
  gramLimitPerOrder: 30,
  catalogueCursor: 0,
  cartPerProductLimit: 50,
  compareProductLimit: 4,
  catalogueCursorLimit: 16,
  idleTime: 30,
  uniqueOrderHours: 24,
  view: 'grid',
  // Default sort option: { name: 'asc' }
  // { name: 'asc' } - Name ascending
  // { name: 'desc' } - Name descending
  // { price: 'asc' } - Price ascending
  // { price: 'desc' } - Price descending
  sort: { name: 'asc' },
  filterSets: {
    default: {
      sections: [filterSection['Brand']],
    },
    Dried: {
      sections: [
        filterSection['Brand'],
        filterSection['CBD per selling unit'],
        filterSection['THC per selling unit'],
        filterSection['Produced in'],
        filterSection['Type'],
        filterSection['Terpenes'],
      ],
    },
    'Ingestible Extracts': {
      sections: [
        filterSection['Brand'],
        filterSection['CBD per selling unit'],
        filterSection['THC per selling unit'],
        filterSection['Produced in'],
        filterSection['Type'],
        filterSection['Carrier oil'],
        filterSection['Consumption method'],
        filterSection['Terpenes'],
      ],
    },
    'Inhalable Extracts': {
      sections: [
        filterSection['Brand'],
        filterSection['CBD per selling unit'],
        filterSection['THC per selling unit'],
        filterSection['Produced in'],
        filterSection['Type'],
        filterSection['Terpenes'],
      ],
      'Vape Kits & Cartridges': {
        sections: [
          filterSection['Brand'],
          filterSection['CBD per selling unit'],
          filterSection['THC per selling unit'],
          filterSection['Produced in'],
          filterSection['Type'],
          filterSection['Flavour'],
          filterSection['Carrier oil'],
          filterSection['Terpenes'],
          // filterSection['Cartridge connection type'],
          // filterSection['Charger type'],
        ],
      },
      'Hash & Rosin': {
        sections: [
          filterSection['Brand'],
          filterSection['CBD per selling unit'],
          filterSection['THC per selling unit'],
          filterSection['Produced in'],
          filterSection['Type'],
          filterSection['Consumption method'],
          filterSection['Terpenes'],
        ],
      },
    },
    Edibles: {
      sections: [
        filterSection['Brand'],
        filterSection['CBD per selling unit'],
        filterSection['THC per selling unit'],
        filterSection['Produced in'],
        filterSection['Type'],
        filterSection['Flavour'],
        filterSection['Allergen information'],
        filterSection['Terpenes'],
      ],
      Beverages: {
        sections: [
          filterSection['Brand'],
          filterSection['CBD per selling unit'],
          filterSection['THC per selling unit'],
          filterSection['Produced in'],
          filterSection['Type'],
          filterSection['Flavour'],
          // filterSection['Container size'],
          filterSection['Allergen information'],
          filterSection['Terpenes'],
        ],
      },
    },
    Accessories: {
      sections: [filterSection['Brand']],
    },
  },
  filterSection: { ...filterSection },
  terpenesCopy: '',
  terpenes: {
    other: {
      name: 'Other',
      color: '#d9dbda',
      description:
        'BC Cannabis Stores has identified some common terpenes in cannabis, but there are more than 200 others that may be present in varying lower percentages. As a guide to help you find your way, up to three of the most dominant terpenes are displayed along with their respective percentages in the terpene wheel above. Some of the many other terpenes may include: bergamotene, elemene, eudesmol, guaiol, santalene, selinadiene, and ylangene.',
    },
    unknown: {
      name: 'Unknown',
      color: '#000000',
      description: 'This description will be updated soon.',
    },
  },
  footerCopy: [],
  orderIdModalHeader: 'PLEASE ENTER A UNIQUE\n4 NUMBER COMBINATION',
  orderIdModalBody:
    'This will be your unique order number.\nOne of our staff will call this number when your order is ready.',
  orderIdModalTitle: 'Your unique order#',
  orderNumber: ['', '', '', ''],
  errorMessage: '',
  keyboard: false,
});

// Reducer
export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case `${SET_COMPARE_PRODUCTS}`:
      return state
        .set('compareProducts', fromJS(action.payload.products))
        .set('modalStatus', fromJS(action.payload.status))
        .set('showErrors', action.payload.showError);
    case `${POST_ORDER}_FULFILLED`:
      return state.set('orderNumber', fromJS(['', '', '', ''])).set('cartProducts', fromJS(action.payload));
    case `${SET_SEARCH_INPUT}`:
      return state.set('searchInput', fromJS(action.payload));
    case `${SET_ORDER_NUMBER}`:
      return state.set('orderNumber', fromJS(action.payload));
    case `${SET_ERROR_MESSAGE}`:
      return state.set('errorMessage', fromJS(action.payload));
    case `${SET_MENU_ITEM}`:
      return state.set('focusMenuItem', fromJS(action.payload));
    case `${SET_CATALOGUE_CURSOR}`:
      return state.set('catalogueCursor', fromJS(action.payload));
    case `${CLEAR_RECENTLY_ADDED_PRODUCTS}`:
      return state.set('recentlyAddedProducts', fromJS([]));
    case `${SET_CART_PRODUCTS}`:
      return state
        .set('cartProducts', fromJS(action.payload.products))
        .set('recentlyAddedProducts', fromJS(action.payload.newProducts))
        .set('modalStatus', fromJS(action.payload.status));
    case `${SET_MODAL_STATUS}`:
      return state.set('modalStatus', fromJS(action.payload));
    case `${SET_SCREEN_HISTORY}`:
      return state.set('screenHistory', fromJS(action.payload));
    case `${SET_DETAIL_PRODUCT}`:
      return state.set('detailProduct', fromJS(action.payload));
    case `${GET_APP_CONFIG_FULFILLED}`:
      return state
        .set('cartPerProductLimit', fromJS(action.payload.maxProductQuantity))
        .set('compareProductLimit', fromJS(action.payload.maxComparedProducts))
        .set('gramLimitPerOrder', fromJS(action.payload.maxGramsPerOrder))
        .set('orderIdModalHeader', fromJS(action.payload.orderIdModalHeader).replace(/(?:\r\n|\r|\n)/g, '\n'))
        .set('orderIdModalBody', fromJS(action.payload.orderIdModalBody).replace(/(?:\r\n|\r|\n)/g, '\n'))
        .set('orderIdModalTitle', fromJS(action.payload.orderIdModalTitle))
        .set('footerCopy', fromJS(action.payload.footerCopy))
        .set('terpenesCopy', fromJS(action.payload.terpenesCopy))
        .set('terpenes', fromJS(action.payload.terpenes));
    case `${GET_STORE_CONFIG_FULFILLED}`:
      return state
        .set('idleTime', fromJS(action.payload.idleTimer))
        .set('uniqueOrderHours', fromJS(action.payload.uniqueOrderHours))
        .set('view', fromJS(action.payload.defaultDisplayView))
        .set('storeName', fromJS(action.payload.storeName))
        .set('printerIpAddress', fromJS(action.payload.printerIpAddress))
        .set('refreshOrdersInterval', fromJS(action.payload.refreshOrdersInterval));
    case `${RESET_SESSION}`:
      return state
        .set('compareProducts', fromJS([]))
        .set('showErrors', fromJS(false))
        .set('detailProduct', fromJS({}))
        .set('productFilters', fromJS([]))
        .set('screenHistory', fromJS(['welcome']))
        .set('catalogueCursor', fromJS(0))
        .set('cartProducts', fromJS([]))
        .set('recentlyAddedProducts', fromJS([]))
        .set('focusMenuItem', fromJS(''))
        .set('searchInput', fromJS(''))
        .set('errorMessage', fromJS(''))
        .set('orderNumber', fromJS(['', '', '', '']))
        .set('showSearchProducts', fromJS(false))
        .set('searchProducts', fromJS({ items: [], isFetching: false, timestamp: 0 }))
        .set('products', fromJS({ items: [], cache: {}, isFetching: false, isPolling: false, pagination: { page: 1, limit: productsPaginationLimit, total: 1 } }))
        .set('modalStatus', fromJS({}))
        .set('filters', fromJS([]))
        .set('sort', fromJS({ name: 'asc' }));
    case `${SET_VIEW}`:
      return state.set('view', fromJS(action.payload));
    case `${SET_SORT}`:
      return state.set('sort', fromJS(action.payload));
    case `${SET_PRODUCTS_PAGINATION}`:
      return state.mergeIn(['products', 'pagination'], fromJS(action.payload));
    case `${SET_SHOW_SEARCH_PRODUCTS}`:
      return state.set('showSearchProducts', fromJS(action.payload));
    case `${SET_SEARCH_PRODUCTS}`:
      return state.updateIn(['searchProducts'], (item) =>
        item
          .set('items', fromJS(action.payload))
          .set('isFetching', false)
          .set('hasFetched', true)
      );
    case `${RESET_SEARCH_PRODUCTS}`:
      return state.set('searchProducts', initialState.get('searchProducts'));
    case `${SET_KEYBOARD}`:
      return state.set('keyboard', fromJS(action.payload));
    case `${SET_STORE_ID}`:
      return state.set('storeID', fromJS(action.payload));
    case `${GET_FILTERS_FULFILLED}`:
      return state.set('filters', fromJS(action.payload));
    case `${GET_PRODUCTS}_FULFILLED`:
      return state.set('products', fromJS(action.payload));
    case GET_FILTERED_PRODUCTS_PENDING:
      return state.setIn(['products', 'isFetching'], true);
    case GET_FILTERED_PRODUCTS_FULFILLED: {
      const { edges, pagination } = action.payload;
      const items = mergeProducts(state.getIn(['products', 'items']), fromJS(edges));
      const total = pagination[0] ? pagination[0].totalPages : 1;
      return state.updateIn(['products'], (item) =>
        item
          .set('items', items)
          .set('isFetching', false)
          .setIn(['pagination', 'total'], total)
          .mergeIn(['cache'], {
            [action.cache]: {
              items,
              pagination: state.getIn(['products', 'pagination']).setIn(['total'], total),
            },
          })
      );
    }
    case SET_FILTERED_PRODUCTS:
      return state.mergeIn(['products'], action.payload);
    case RESET_FILTERED_PRODUCTS: {
      return state.updateIn(['products'], (item) =>
        item
          .set('items', fromJS([]))
          .set('pagination', fromJS({ page: 1, limit: productsPaginationLimit }))
      );
    }
    case SET_FILTERED_PRODUCTS_POLLING:
      return state.setIn(['products', 'isPolling'], action.payload);
    case GET_SEARCH_PRODUCTS_PENDING:
      return state.setIn(['searchProducts', 'isFetching'], true);
    case SET_SEARCH_TIMESTAMP:
      return state.setIn(['searchProducts', 'timestamp'], action.payload);
    default:
      return state;
  }
}
