/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import promiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import createReducer from './reducers';


const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    sagaMiddleware,
    thunk,
    promiseMiddleware(),
    routerMiddleware(history),
  ];

  /**
   * Development only middleware
   */
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'uat') {
    /* eslint-disable global-require */

    // Bug catching, freeze the redux store from mutation during development
    const freeze = require('redux-freeze');
    middlewares.push(freeze);

    // Console logging of redux actions
    const { createLogger } = require('redux-logger');
    const logger = createLogger({
      collapsed: true,
      colors: {
        title: () => 'darkslategray',
        prevState: () => 'darkviolet',
        action: () => 'darkcyan',
        nextState: () => 'green',
        error: () => 'firebrick',
      },
      predicate: () => true, // Turn logger on/off easily
    });
    middlewares.push(logger);

    /* eslint-enable */
  }

  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'uat') &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? /* istanbul ignore next */
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : /* istanbul ignore next */
      compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    fromJS({
      ...initialState,
    }),
    composeEnhancers(...enhancers)
  );


  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.asyncReducers = {}; // Async reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore if */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      import('./reducers').then((reducerModule) => {
        const createReducers = reducerModule.default;
        const nextReducers = createReducers(store.asyncReducers);

        store.replaceReducer(nextReducers);
      });
    });
  }

  return store;
}
