const fontFix = `
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export default {
  heading: `
    font-family: 'Gill Sans', sans-serif;
    letter-spacing: 1px;
    font-weight: 400;
    text-transform: uppercase;
    ${fontFix}
  `,
  modalheading: `
    font-family: 'Gill Sans', sans-serif;
    letter-spacing: 2px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3;
    font-style: normal;
    font-stretch: normal;
    ${fontFix}
  `,
  body: `
    font-family: 'Muli', sans-serif;
    font-weight: normal;
    line-height: 1.3;
    font-style: normal;
    ${fontFix}
  `,
  misc: `
    font-family: 'Muli', sans-serif;
    ${fontFix}
  `,
  italic: `
    font-family: 'Muli', sans-serif;
    font-weight: normal;
    font-style: italic;
    ${fontFix}
  `,
  bold: `
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    font-style: normal;
    ${fontFix}
  `,
  bolditalic: `
    font-family: 'Muli', sans-serif;
    font-weight: bold;
    font-style: italic;
    ${fontFix}
  `,
  light: `
    font-family: 'Muli', sans-serif;
    font-weight: 300;
    font-style: normal;
    ${fontFix}
  `,
  lightitalic: `
    font-family: 'Muli', sans-serif;
    font-weight: 300;
    font-style: italic;
    ${fontFix}
  `,
  extralight: `
    font-family: 'Muli', sans-serif;
    font-weight: 200;
    font-style: normal;
    ${fontFix}
  `,
  extralightitalic: `
    font-family: 'Muli', sans-serif;
    font-weight: 200;
    font-style: italic;
    ${fontFix}
  `,
};
