import { Api, CraftApi } from 'config';

import {
  // SET_DETAIL_PRODUCT,
  SET_COMPARE_PRODUCTS,
  // SET_SCREEN_HISTORY,
  // SET_PRODUCT_FILTER,
  SET_ORDER_NUMBER,
  SET_SCREEN_HISTORY,
  SET_CART_PRODUCTS,
  CLEAR_RECENTLY_ADDED_PRODUCTS,
  SET_CATALOGUE_CURSOR,
  RESET_SESSION,
  SET_DETAIL_PRODUCT,
  SET_STORE_ID,
  SET_MODAL_STATUS,
  SET_SEARCH_INPUT,
  SET_MENU_ITEM,
  GET_APP_CONFIG,
  POST_ORDER,
  GET_STORE_CONFIG,
  SET_VIEW,
  SET_SORT,
  SET_SHOW_SEARCH_PRODUCTS,
  SET_SEARCH_PRODUCTS,
  SET_ERROR_MESSAGE,
  SET_KEYBOARD,
  GET_PRODUCTS,
  GET_FILTERS,
  GET_FILTERED_PRODUCTS,
  REFRESH_FILTERED_PRODUCTS_POLLING,
  SET_PRODUCTS_PAGINATION,
  RESET_FILTERED_PRODUCTS,
  SET_SEARCH_TIMESTAMP,
} from '../constants';

// eslint-disable-next-line func-names
Object.byString = function (o, s) {
  // eslint-disable-next-line no-param-reassign
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  // eslint-disable-next-line no-param-reassign
  s = s.replace(/^\./, ''); // strip a leading dot
  const a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      // eslint-disable-next-line no-param-reassign
      o = o[k];
    } else {
      return;
    }
  }
  // eslint-disable-next-line consistent-return
  return o;
};

function list(arr, el, elLimit, cKey, accumKey = null, accum = false, accumLimit = -1) {
  const newArr = arr;
  if (el === null) return { arr: [], error: false };

  // eslint-disable-next-line one-var
  let col,
    val;
  if (cKey !== undefined) {
    val = Object.byString(el, cKey);
    col = newArr.map((_) => Object.byString(_, cKey));
  }

  let dAmount;

  if (col !== undefined && col.includes(val)) {
    // This is for list that have accumalation of an item, this key signifies which key the accumalation will be stored in
    if (accumKey) {
      let action = 'added';
      const index = newArr.findIndex((_) => Object.byString(_, cKey) === val);
      if (accum) {
        newArr[index].quantity += el.quantity;
      } else {
        if (!el.quantity || el.quantity < 1) return { arr: newArr.filter((_) => Object.byString(_, cKey) !== val), error: false, action: 'removed' };
        dAmount = el.quantity - JSON.stringify(newArr[index].quantity);
        if (dAmount < 0) action = 'removed';
        newArr[index].quantity = el.quantity;
      }
      if (newArr[index].quantity > accumLimit) newArr[index].quantity = accumLimit;
      return { arr: newArr, error: false, action, delta: dAmount };
    }
    return { arr: newArr.filter((_) => Object.byString(_, cKey) !== val), error: false, action: 'remove' };
  } else if (arr.includes(el)) {
    return { arr: newArr.filter((_) => _ !== el), error: false, action: 'remove' };
  } else if (elLimit > 0 && arr.length >= elLimit) return { arr: newArr.slice(0, elLimit), error: true, log: 'Array limit exceeded.', action: 'added' };

  return { arr: [...newArr, el], error: false, action: 'added', delta: dAmount };
}

export function setCompareProducts(product) {
  return (dispatch, getState) => dispatch({
    type: SET_COMPARE_PRODUCTS,
    payload: (() => {
      const limit = getState().getIn(['shop', 'compareProductLimit']);
      const products = getState().getIn(['shop', 'compareProducts']).toJS();
      const resp = list(products, product, limit, 'productData.baseSku');

      const status = getState().getIn(['shop', 'modalStatus']).toJS();

      // Check grams currently in the order
      if (!status.compareLimitWarning) status.compareLimitWarning = {};
      const { compareLimitWarning } = status;
      const compareLimit = getState().getIn(['shop', 'compareProductLimit']);
      compareLimitWarning.overage = (resp.action === 'added' ? products.length : resp.arr.length) === compareLimit;
      if (!compareLimitWarning.overage) compareLimitWarning.warned = false;

      return { products: resp.arr, showError: resp.error, status };
    })(),
  });
}

export function setScreenHistory(screen, resetType = 0) {
  return (dispatch, getState) => dispatch({
    type: SET_SCREEN_HISTORY,
    payload: (() => {
      const screens = getState().getIn(['shop', 'screenHistory']).toJS();

      if (resetType === 2 || (resetType && screens[0] !== screen)) return [screen];
      if (!screen || screens[0] === screen) return screens.slice(1);

      return [screen, ...screens];
    })(),
  });
}

export function setCartProducts(
  product,
  option,
  quantity,
  accum = false,
  overrideLimit,
  notify = true
) {
  return (dispatch, getState) =>
    dispatch({
      type: SET_CART_PRODUCTS,
      payload: (() => {
        const _ = option
          ? {
            ...option,
            productData: product.productData,
            quantity,
          }
          : {
            ...product,
            quantity,
          };

        const limit =
          overrideLimit || getState().getIn(['shop', 'cartPerProductLimit']);
        const products = getState().getIn(['shop', 'cartProducts']).toJS();
        const resp = list(products, _, -1, 'subsku', 'quantity', accum, limit);
        const newProducts =
          notify && resp.action === 'added' ? [{ ..._, addedAt: new Date() }] : [];
        if (newProducts[0] && resp.delta > 0) { newProducts[0].quantity = resp.delta; }

        const status = getState().getIn(['shop', 'modalStatus']).toJS();

        // Check grams currently in the order
        if (!status.cartCapacityWarning) status.cartCapacityWarning = {};
        const { cartCapacityWarning } = status;

        const gramCount = resp.arr.reduce((t, item) => t + (item.quantity * item.volumeEquivalentSize), 0);

        const gramLimit = getState().getIn(['shop', 'gramLimitPerOrder']);
        cartCapacityWarning.overage = gramCount > gramLimit;
        if (!cartCapacityWarning.overage) cartCapacityWarning.warned = false;

        return { products: resp.arr, status, newProducts };
      })(),
    });
}

export function setCartQuantityStatus(key) {
  return (dispatch, getState) => dispatch({
    type: SET_MODAL_STATUS,
    payload: (() => {
      const status = getState().getIn(['shop', 'modalStatus']).toJS();
      status[key].warned = true;
      return status;
    })(),
  });
}

export function setCatalogueCursor(cursor) {
  return (dispatch) => dispatch({
    type: SET_CATALOGUE_CURSOR,
    payload: cursor,
  });
}

export function clearRecentlyAddedProducts() {
  return (dispatch) =>
    dispatch({
      type: CLEAR_RECENTLY_ADDED_PRODUCTS,
    });
}

export function resetSession() {
  return (dispatch) => dispatch({
    type: RESET_SESSION,
  });
}

export function setDetailProduct(product) {
  return (dispatch) => dispatch({
    type: SET_DETAIL_PRODUCT,
    payload: product === null ? {} : product,
  });
}

export function setStoreID(id) {
  return (dispatch) => dispatch({
    type: SET_STORE_ID,
    payload: (() => id)(),
  });
}

export function setMenuItem(value) {
  return (dispatch) => dispatch({
    type: SET_MENU_ITEM,
    payload: value,
  });
}

export function setView(view) {
  return (dispatch) => dispatch({
    type: SET_VIEW,
    payload: view,
  });
}

export function setSort(sort) {
  return (dispatch) => dispatch({
    type: SET_SORT,
    payload: sort,
  });
}

export function setShowSearchProducts(showSearchProducts) {
  return (dispatch) => dispatch({
    type: SET_SHOW_SEARCH_PRODUCTS,
    payload: showSearchProducts,
  });
}

export function setSearchProducts(searchProducts) {
  return (dispatch) => dispatch({
    type: SET_SEARCH_PRODUCTS,
    payload: searchProducts,
  });
}

export function setSearchInput(value) {
  return (dispatch) => dispatch({
    type: SET_SEARCH_INPUT,
    payload: value,
  });
}

export function setOrderNumber(index, value) {
  return (dispatch, getState) => dispatch({
    type: SET_ORDER_NUMBER,
    payload: (() => {
      const arr = getState().getIn(['shop', 'orderNumber']).toJS();
      if (index === -1) return arr.map(() => '');
      arr[index] = value;
      return arr;
    })(),
  });
}

export function setErrorMessage(value) {
  return (dispatch) => dispatch({
    type: SET_ERROR_MESSAGE,
    payload: value,
  });
}

export function postOrder(storeId, callback) {
  return (dispatch, getState) => dispatch({
    type: POST_ORDER,
    payload: new Promise((resolve, reject) => {
      const orderNumber = getState().getIn(['shop', 'orderNumber']).toJS().join('');
      const products = getState().getIn(['shop', 'cartProducts']).toJS();
      const uniqueOrderHours = getState().getIn(['shop', 'uniqueOrderHours']);
      // const inventory = getState().getIn(['app', 'inventory']).toJS();
      // let inventoryFlag = false;

      // const updatedProducts = products.map((item) => {
      //   const _ = inventory.find((y) => item.subsku === y.sku);
      //   let quantityFlag;
      //   let { quantity } = item;
      //   const i = _ ? parseInt(_.available, 10) : 0;
      //   if (i < quantity) {
      //     inventoryFlag = true;
      //     quantityFlag = quantity;
      //     quantity = i;
      //   }

      //   return ({ ...item, inventory: i, quantity, quantityFlag });
      // });

      // if (inventoryFlag) return resolve(updatedProducts);

      const itemList = products.map((prod) => {
        const { subsku: sku, quantity } = prod;
        return { sku, quantity };
      });

      return Api.post('orders', {
        storeId,
        orderNumber,
        uniqueHours: uniqueOrderHours,
        products: itemList,
      })
        .then(({ status }) => {
          if (status !== 200) return reject(new Error('postOrder didn\'t return status 200'));
          if (callback !== undefined) callback(orderNumber);
          return resolve([]);
        })
        .catch((error) => {
          reject(error);
        });
    }),
  });
}

export function getAppConfig() {
  return (dispatch) => dispatch({
    type: GET_APP_CONFIG,
    payload: new Promise((resolve, reject) => {
      CraftApi.post('', {
        query: `query {
        entry(section: "appConfiguration") {
          ... on appConfiguration_appConfiguration_Entry {
            maxProductQuantity
            maxComparedProducts
            maxGramsPerOrder
            footerCopy {
              ... on footerCopy_BlockType {
                lineOfText
              }
            }
            orderIdModalHeader
            orderIdModalBody
            orderIdModalTitle
            terpenesCopy
            terpenes {
              ... on terpenes_BlockType {
                terpeneName
                color
                description
              }
            }
          }
        }
      }` })
        .then(({ data }) => {
          const { maxProductQuantity, maxComparedProducts, maxGramsPerOrder, footerCopy, orderIdModalHeader, orderIdModalBody, orderIdModalTitle, terpenesCopy, terpenes } = data.data.entry;
          const convertArrayToObject = (array, key) => {
            const initialValue = {};
            return array.reduce((obj, item) => ({
              ...obj,
              [item[key].toLowerCase()]: { name: item.terpeneName, color: item.color, description: item.description },
            }), initialValue);
          };
          const terpenesObj = convertArrayToObject(terpenes, 'terpeneName');

          resolve({
            maxProductQuantity,
            maxComparedProducts,
            maxGramsPerOrder,
            footerCopy,
            orderIdModalHeader,
            orderIdModalBody,
            orderIdModalTitle,
            terpenesCopy,
            terpenes: terpenesObj,
          });
        })
        .catch((error) => reject(error));
    }),
  });
}

export function getStoreConfig(storeId) {
  return (dispatch) => dispatch({
    type: GET_STORE_CONFIG,
    payload: new Promise((resolve, reject) => {
      CraftApi.post('', {
        query: `query {
        entry(section: "stores", storeNumber: ${storeId}) {
          ... on stores_storeConfigurations_Entry {
            storeName
            storeNumber
            city
            defaultDisplayView
            idleTimer
            printerIpAddress
            refreshOrdersInterval
            uniqueOrderHours
          }
        }
      }` })
        .then(({ data }) => {
          const storeConfig = data.data.entry;
          // const screensObj = {};
          // storeConfig.screens.forEach((screen) => {
          //   screensObj[screen.screenId] = screen;
          // });
          // storeConfig.screens = screensObj;
          resolve(storeConfig);
        })
        .catch((error) => reject(error));
    }),
  });
}

export function setKeyboard(keyboard) {
  return (dispatch) => dispatch({
    type: SET_KEYBOARD,
    payload: keyboard,
  });
}

// Welcome Screen Actions

export function getProducts(storeId) {
  return (dispatch) =>
    dispatch({
      type: GET_PRODUCTS,
      payload: new Promise((resolve, reject) => {
        Api.get(`products/base?storeID=${storeId}`)
          .then(({ status, data }) => {
            if (status !== 200) {
              return reject(new Error("getProducts didn't return status 200"));
            }
            return resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    });
}

/**
 * Dispatches an action to fetch filters.
 * 
 * @returns {Object} An action of type GET_FILTERS.
 */
export function getFilters() {
  return {
    type: GET_FILTERS,
  };
}

/**
 * Dispatches an action to start fetching filtered products.
 * 
 * @returns {Object} An action of type GET_FILTERED_PRODUCTS.
 */
export function getFilteredProducts() {
  return {
    type: GET_FILTERED_PRODUCTS,
  };
}

/**
 * Dispatches an action to refresh the products fetching polling.
 * 
 * @returns {Object} An action of type REFRESH_FILTERED_PRODUCTS_POLLING.
 */
export function resetFilteredProductsPolling() {
  return {
    type: REFRESH_FILTERED_PRODUCTS_POLLING,
  };
}

export function setProductsPagination(payload) {
  return {
    type: SET_PRODUCTS_PAGINATION,
    payload,
  };
}

export function resetFilteredProducts() {
  return {
    type: RESET_FILTERED_PRODUCTS,
  };
}

export function setSearchTimestamp(timestamp) {
  return {
    type: SET_SEARCH_TIMESTAMP,
    payload: timestamp,
  };
}
