import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { refreshInventoryTime, refreshProductTime, refreshConfigTime, checkVersionTime } from './../../config';
import { getStores, getProducts, getInventory, getScreenConfig } from 'globalReducers/app';
import { getStoreConfig } from 'containers/ShopScreen/reducers/catalogue/actions';
import { Site } from 'theme/global-styles';
import { checkVersion } from 'utils';
import { selectBaseProductFilters } from 'selectors/app';

class App extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    getStores: PropTypes.func,
    getProducts: PropTypes.func,
    getInventory: PropTypes.func,
    getScreenConfig: PropTypes.func,
    getStoreConfig: PropTypes.func,
    router: PropTypes.object,
  };

  componentWillMount() {
    this.props.getStores();
    const { router } = this.props;

    if (router.params !== undefined && router.params.storeNumber !== undefined) {
      const storeID = router.params.storeNumber;
      const screen = router.params.screen;

      if (router.location.pathname.includes('/instore/') && screen) {
        // refresh config
        this.props.getScreenConfig(storeID, screen);
        setInterval(() => {
          this.props.getScreenConfig(storeID, screen);
        }, refreshConfigTime);
        // refresh product
        this.props.getProducts(storeID, screen);
        setInterval(() => {
          this.props.getProducts(storeID, screen);
        }, refreshProductTime);
      } else if (router.location.pathname.includes('/shop/')) {
        // refresh store config
        this.props.getStoreConfig(storeID);
        setInterval(() => {
          this.props.getStoreConfig(storeID);
        }, refreshConfigTime);
      } else if (router.location.pathname.includes('/adminOrder/')) {
        // refresh store config
        this.props.getStoreConfig(storeID);
        setInterval(() => {
          this.props.getStoreConfig(storeID);
        }, refreshConfigTime);

        this.props.getProducts(storeID);
        setInterval(() => {
          this.props.getProducts(storeID);
        }, refreshProductTime);
      }

      // refresh inventory (except for routes that are using the updated API)
      if (!router.location.pathname.includes('/shop/')) {
        this.props.getInventory(storeID);
        setInterval(() => {
          this.props.getInventory(storeID);
        }, refreshInventoryTime);
      }

      if (process.env.NODE_ENV !== 'development') {
        setInterval(() => {
          checkVersion();
        }, checkVersionTime);
      }
    }
  }

  render() {
    return (
      <Site>
        <Helmet title="Store Price Display" />
        {React.Children.toArray(this.props.children)}
      </Site>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  productFilters: selectBaseProductFilters,
});

const mapDispatchToProps = {
  getStores,
  getProducts,
  getInventory,
  getScreenConfig,
  getStoreConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
