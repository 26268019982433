import { createSelector } from 'reselect';

const selectApp = () => (state) => state.get('app');

const selectStores = () => createSelector(
  selectApp(),
  (state) => state.get('stores')
);

const selectProducts = () => createSelector(
  selectApp(),
  (state) => state.get('products')
);

const selectInventory = () => createSelector(
  selectApp(),
  (state) => state.get('inventory')
);

const selectSearch = () => createSelector(
  selectApp(),
  (state) => state.get('search')
);

const selectFavourites = () => createSelector(
  selectApp(),
  (state) => state.get('favourites')
);

const selectDate = () => createSelector(
  selectApp(),
  (state) => state.get('date')
);

const selectInstoreScreenProducts = () => createSelector(
  selectApp(),
  (state) => state.get('instoreScreenProducts')
);

const selectProductLoaded = () => createSelector(
  selectApp(),
  (state) => state.get('productsLoaded')
);

const selectConfig = () => createSelector(
  selectApp(),
  (adminState) => adminState.get('config'),
);

const selectCompareProducts = () => createSelector(
  selectApp(),
  (state) => state.get('compareProducts')
);

const selectComparePaneVisibility = () => createSelector(
  selectApp(),
  (state) => state.get('showComparePane')
);

const selectComparePaneOffset = () => createSelector(
  selectApp(),
  (state) => state.get('comparePaneOffset')
);

const selectShowError = () => createSelector(
  selectApp(),
  (state) => state.get('showError')
);

const selectAppConfig = () => createSelector(
  selectApp(),
  (state) => state.get('appConfig')
);

const selectStoreConfig = () => createSelector(
  selectApp(),
  (state) => state.get('storeConfig')
);

const selectNavConfig = () => createSelector(
  selectApp(),
  (state) => state.get('navConfig')
);

const selectBaseProductFilters = () => createSelector(
  selectApp(),
  (state) => state.get('baseProductFilters')
);

const selectProductFilters = () => createSelector(
  selectApp(),
  (state) => state.get('productFilters')
);

const selectCurrentShopMenu = () => createSelector(
  selectApp(),
  (state) => state.get('currentShopMenu')
);

const selectLastFilterSection = () => createSelector(
  selectApp(),
  (state) => state.get('lastFilterSection')
);

const selectSearchResults = () => createSelector(
  selectApp(),
  (state) => state.get('searchResults')
);

export {
  selectStores,
  selectProducts,
  selectInventory,
  selectSearch,
  selectFavourites,
  selectDate,
  selectInstoreScreenProducts,
  selectProductLoaded,
  selectConfig,
  selectCompareProducts,
  selectComparePaneVisibility,
  selectComparePaneOffset,
  selectShowError,
  selectAppConfig,
  selectStoreConfig,
  selectNavConfig,
  selectProductFilters,
  selectBaseProductFilters,
  selectCurrentShopMenu,
  selectLastFilterSection,
  selectSearchResults,
};
