import { fromJS } from 'immutable';

import {
  SET_CURRENT_TAB,
  GET_ORDERS,
  UPDATE_ORDER,
  SET_ORDERS_FROM,
} from './constants';

// Initial State
const initialState = fromJS({
  currentTab: 'active',
  orders: [],
  ordersFrom: 'day',
});

// Reducer
export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case `${UPDATE_ORDER}_FULFILLED`:
    case `${GET_ORDERS}_FULFILLED`:
      return state
        .set('orders', fromJS(action.payload));
    case `${SET_CURRENT_TAB}`:
      return state
        .set('currentTab', fromJS(action.payload));
    case `${SET_ORDERS_FROM}`:
      return state
        .set('ordersFrom', fromJS(action.payload));
    default:
      return state;
  }
}
