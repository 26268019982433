export async function checkVersion() {
  const thisVersion = process.env.VERSION;
  try {
    const versionFile = await fetch('/version.json').then((res) => res.json());
    const latestVersion = versionFile.version;
    if (latestVersion && thisVersion !== latestVersion) {
      const indexStatus = await fetch('/index.html').then((res) => res.status);
      if (indexStatus === 200) {
        window.location.reload(true);
      }
    }
  } catch (err) {
    throw new Error('Error in checkVersion. Could not fetch valid version file from server');
  }
}

export default checkVersion;
