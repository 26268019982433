import { fromJS } from 'immutable';
import { Api, CraftApi, loginRoute } from './../config';
import dried from 'assets/images/welcome/dried.jpg';
import ingestible_extracts from 'assets/images/welcome/ingestible_extracts.jpg';
import inhalable_extracts from 'assets/images/welcome/inhalable_extracts.jpg';
import accessories from 'assets/images/welcome/accessories.jpg';
import edibles from 'assets/images/welcome/edibles.jpg';
// import shop_all from 'assets/images/welcome/shop_all.jpg';
// dried images
import flower from 'assets/images/welcome/dried/flower.jpg';
import preroll from 'assets/images/welcome/dried/preroll.jpg';
import seeds from 'assets/images/welcome/dried/seeds.jpg';
// ingestible extracts images
import oils from 'assets/images/welcome/ingestible_extracts/oils.jpg';
import capsules from 'assets/images/welcome/ingestible_extracts/capsules.jpg';
// inhalable extracts images
import vape_kits_cartridges from 'assets/images/welcome/inhalable_extracts/vape_kits_cartridges.jpg';
import hash_rosin from 'assets/images/welcome/inhalable_extracts/hash_rosin.jpg';
// edibles images
import chocolate from 'assets/images/welcome/edibles/chocolate.jpg';
import baked_goods_snacks from 'assets/images/welcome/edibles/baked_goods_snacks.jpg';
import beverages from 'assets/images/welcome/edibles/beverages.jpg';
import chews from 'assets/images/welcome/edibles/chews.jpg';
// accessories images
import bongs_pipes from 'assets/images/welcome/accessories/bongs_pipes.jpg';
import preparation from 'assets/images/welcome/accessories/preparation.jpg';
import storage_cleaning from 'assets/images/welcome/accessories/storage_cleaning.jpg';
import vaporizers_chargers from 'assets/images/welcome/accessories/vaporizers_chargers.jpg';

// Actions
const GET_STORES = 'globalReducer/app/GET_STORES';
const GET_STORES_FULFILLED = 'globalReducer/app/GET_STORES_FULFILLED';
const GET_PRODUCTS = 'globalReducer/app/GET_PRODUCTS';
const GET_PRODUCTS_FULFILLED = 'globalReducer/app/GET_PRODUCTS_FULFILLED';
const GET_FILTERED_PRODUCTS = 'globalReducer/app/GET_FILTERED_PRODUCTS';
// const GET_STORE_ADMIN = 'globalReducer/app/GET_STORE_ADMIN';
// const GET_STORE_ADMIN_FULFILLED = 'globalReducer/app/GET_STORE_ADMIN_FULFILLED';
const GET_SCREEN_CONFIG = 'globalReducer/app/GET_SCREEN_CONFIG';
const GET_SCREEN_CONFIG_FULFILLED = 'globalReducer/app/GET_SCREEN_CONFIG_FULFILLED';
const GET_INVENTORY = 'globalReducer/app/GET_INVENTORY';
const GET_INVENTORY_FULFILLED = 'globalReducer/app/GET_INVENTORY_FULFILLED';
const MODIFY_COMPARISON_PRODUCT = 'globalReducer/app/MODIFY_COMPARISON_PRODUCT';
const TOGGLE_COMPARE_PANE = 'globalReducer/app/TOGGLE_COMPARE_PANE';
const SET_COMPARE_PANE_OFFSET = 'globalReducer/app/SET_COMPARE_PANE_OFFSET';
const TOGGLE_SHOW_ERROR = 'globalReducer/app/TOGGLE_SHOW_ERROR';
const GET_APP_CONFIG = 'globalReducer/app/GET_APP_CONFIG';
const GET_NAV_CONFIG = 'globalReducer/app/GET_NAV_CONFIG';
const SET_CURRENT_SHOP_MENU = 'globalReducer/app/SET_CURRENT_SHOP_MENU';
const GET_SEARCH_PRODUCTS = 'globalReducer/app/GET_SEARCH_PRODUCTS';
const SET_BASE_PRODUCT_FILTERS = 'globalReducer/app/SET_BASE_PRODUCT_FILTERS';
const SET_PRODUCT_FILTERS = 'globalReducer/app/SET_PRODUCT_FILTERS';
const SET_LAST_FILTER_SECTION = 'globalReducer/app/SET_LAST_FILTER_SECTION';

// Initial State
const initialState = fromJS({
  stores: [],
  products: [],
  inventory: [],
  search: {},
  date: {},
  instoreScreenProducts: [],
  productsLoaded: false,
  config: {
    carouselIntervalTimer: 15,
    productsPerPage: 18,
    marketingImage: null,
    idleTime: 30,
    category: null,
    subcategory: null,
    view: null,
    classes: [],
  },
  compareProducts: [],
  showComparePane: false,
  showError: false,
  comparePaneOffset: 0,
  // Shop states
  appConfig: [{}],
  storeConfig: [{}],
  // Hardcoded Nav Menu
  navConfig: [
    {
      primaryLabel: 'Dried',
      image: dried,
      description: 'Discover dried cannabis flower and pre-rolls in a range of potencies, strains, and terpene profiles.',
      subMenus: [
        {
          label: 'Flower',
          image: flower,
          description: 'Dried flower bud from the cannabis plant, available in indica, sativa, or hybrid strains.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Dried',
              subsection: 'Flower',
            },
          ],
        },
        {
          label: 'Pre-roll',
          image: preroll,
          description: 'Dried flower (ground or milled) in a pre-rolled joint, available in indica, sativa, or hybrid strains.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Dried',
              subsection: 'Pre-roll',
            },
          ],
        },
        {
          label: 'Seeds',
          image: seeds,
          description: 'Cannabis seeds for growing at home.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Dried',
              subsection: 'Seeds',
            },
          ],
        },
        {
          label: 'Shop All',
          image: dried,
          description: 'Browse all of our dried flower cannabis products.',
          type: 'allProducts',
          staticPageUrl: null,
          request: [
            {
              section: 'Dried',
              subsection: 'Shop All',
            },
          ],
        },
      ],
    },
    {
      primaryLabel: 'Ingestible Extracts',
      image: ingestible_extracts,
      description: 'Check out our capsules and oils in various potencies, from high-THC to CBD-dominant or balanced options.',
      subMenus: [
        {
          label: 'Oils',
          image: oils,
          description: 'Cannabis oils intended for ingestion, with a range of THC, CBD, and balanced potencies.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Ingestible Extracts',
              subsection: 'Oils',
            },
          ],
        },
        {
          label: 'Capsules',
          image: capsules,
          description: 'Capsules containing cannabis oil, with a range of THC, CBD, and balanced potencies.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Ingestible Extracts',
              subsection: 'Capsules',
            },
          ],
        },
        {
          label: 'Shop All',
          image: ingestible_extracts,
          description: 'Explore our selection of ingestible extracts and oil-based products.',
          type: 'allProducts',
          staticPageUrl: null,
          request: [
            {
              section: 'Ingestible Extracts',
              subsection: 'Shop All',
            },
          ],
        },
      ],
    },
    {
      primaryLabel: 'Inhalable Extracts',
      image: inhalable_extracts,
      description: 'Have a look at our high-THC and balanced ingestible extracts, from vape pens and cartridges to potent concentrates such as hash, shatter, or rosin.',
      subMenus: [
        {
          label: 'Vape Kits & Cartridges',
          image: vape_kits_cartridges,
          description: 'Disposable vaporizer pens and cartridges that contain potent cannabis concentrate extract.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Inhalable Extracts',
              subsection: 'Vape Kits & Cartridges',
            },
          ],
        },
        {
          label: 'Hash & Rosin',
          image: hash_rosin,
          description: 'Potent pressed extracts and concentrates designed for inhaling.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Inhalable Extracts',
              subsection: 'Hash & Rosin',
            },
          ],
        },
        {
          label: 'Shop All',
          image: inhalable_extracts,
          description: 'Browse our collection of inhalable extracts and concentrates.',
          type: 'allProducts',
          staticPageUrl: null,
          request: [
            {
              section: 'Inhalable Extracts',
              subsection: 'Shop All',
            },
          ],
        },
      ],
    },
    {
      primaryLabel: 'Edibles',
      image: edibles,
      description: 'High-THC, CBD, and balanced edibles and beverages, including carbonated drinks, baked goods, gummies, and chocolates.',
      subMenus: [
        {
          label: 'Chocolate',
          image: chocolate,
          description: 'Milk, dark, and flavoured cannabis-infused chocolate in a range of THC, CBD, and balanced ratios.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Edibles',
              subsection: 'Chocolate',
            },
          ],
        },
        {
          label: 'Baked Goods & Snacks',
          image: baked_goods_snacks,
          description: 'Brownies, cookies, and other cannabis-infused snacks in a range of THC, CBD, and balanced ratios.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Edibles',
              subsection: 'Baked Goods & Snacks',
            },
          ],
        },
        {
          label: 'Beverages',
          image: beverages,
          description: 'Cannabis-infused carbonated beverages and drops designed for drinking.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Edibles',
              subsection: 'Beverages',
            },
          ],
        },
        {
          label: 'Chews & Candy',
          image: chews,
          description: 'Cannabis-infused confectionary in a range of THC, CBD, and balanced ratios.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Edibles',
              subsection: 'Chews & Candy',
            },
          ],
        },
        {
          label: 'Shop All',
          image: edibles,
          description: 'Explore our entire range of cannabis edibles and beverages.',
          type: 'allProducts',
          staticPageUrl: null,
          request: [
            {
              section: 'Edibles',
              subsection: 'Shop All',
            },
          ],
        },
      ],
    },
    {
      primaryLabel: 'Accessories',
      image: accessories,
      description: 'Find everything you need to prep, consume, store, and clean your products, from rolling trays to bongs, pipes, and humidifiers.',
      subMenus: [
        {
          label: 'Preparation',
          image: preparation,
          description: 'Pick up all the prep items you need, from rolling trays to papers.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Accessories',
              subsection: 'Preparation',
            },
          ],
        },
        {
          label: 'Vaporizers & Chargers',
          image: vaporizers_chargers,
          description: 'Discover dried flower and extract vaporizers in a range of sizes, plus battery packs and chargers.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Accessories',
              subsection: 'Vaporizers & Chargers',
            },
          ],
        },
        {
          label: 'Bongs & Pipes',
          image: bongs_pipes,
          description: 'Search our selection of bongs and pipes in a range of colours and styles.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Accessories',
              subsection: 'Bongs & Pipes',
            },
          ],
        },
        {
          label: 'Storage & Cleaning',
          image: storage_cleaning,
          description: 'Keep your cannabis fresh and your accessories clean with these products.',
          type: 'product',
          staticPageUrl: null,
          request: [
            {
              section: 'Accessories',
              subsection: 'Storage & Cleaning',
            },
          ],
        },
        {
          label: 'Shop All',
          image: accessories,
          description: 'Discover our complete collection of accessories for all your needs.',
          type: 'allProducts',
          staticPageUrl: null,
          request: [
            {
              section: 'Accessories',
              subsection: 'Shop All',
            },
          ],
        },
      ],
    },
  ],
  baseProductFilters: [],
  productFilters: {},
  lastFilterSection: '',
  currentShopMenu: {
    primaryLabel: 'All Products',
    secondaryLabel: '',
  },
  searchIndex: null,
  searchResults: [],
});

// Reducer
export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case GET_STORES_FULFILLED:
      return state
        .set('stores', fromJS(action.payload));

    case GET_PRODUCTS_FULFILLED:
      return state
        .set('products', fromJS(action.payload))
        .set('instoreScreenProducts', fromJS(action.payload));

    case `${GET_FILTERED_PRODUCTS}_FULFILLED`:
      return state
        .set('products', fromJS(action.payload.data))
        .set('instoreScreenProducts', fromJS(action.payload.data))
        .set('searchIndex', fromJS(action.payload.searchIndex));

    case GET_INVENTORY_FULFILLED:
      return state
        .set('inventory', fromJS(action.payload))
        .set('productsLoaded', true);

    case GET_SEARCH_PRODUCTS:
      return state
        .set('searchResults', fromJS(action.payload));

    // case GET_STORE_ADMIN_FULFILLED:
    //   return state
    //     .set('config', fromJS(action.payload.data.value));

    case `${GET_APP_CONFIG}_FULFILLED`:
      return state.set('appConfig', fromJS(action.payload));

    case `${GET_NAV_CONFIG}_FULFILLED`:
      return state.set('navConfig', fromJS(action.payload));

    case GET_SCREEN_CONFIG_FULFILLED:
      return state
        .set('config', fromJS(action.payload));

    case MODIFY_COMPARISON_PRODUCT:
      return state
        .set('compareProducts', fromJS(action.payload.products))
        .set('showError', fromJS(action.payload.showError));

    case TOGGLE_COMPARE_PANE:
      return state
        .set('showComparePane', fromJS(action.payload));

    case SET_COMPARE_PANE_OFFSET:
      return state
        .set('comparePaneOffset', fromJS(action.payload));

    case TOGGLE_SHOW_ERROR:
      return state
        .set('showError', false);
    case SET_CURRENT_SHOP_MENU:
      return state
        .set('currentShopMenu', fromJS(action.payload));
    case SET_BASE_PRODUCT_FILTERS:
      return state
        .set('baseProductFilters', fromJS(action.payload));
    case SET_PRODUCT_FILTERS:
      return state
        .set('productFilters', fromJS(action.payload));
    case SET_LAST_FILTER_SECTION:
      return state
        .set('lastFilterSection', fromJS(action.payload));
    default:
      return state;
  }
}

// Action Creators
export function getProducts(storeId, screen) {
  return (dispatch) => dispatch({
    type: GET_PRODUCTS,
    payload: new Promise((resolve, reject) => {
      Api.get(screen ? `products/base?storeID=${storeId}&screen=${screen}` : `products/base?storeID=${storeId}`)
        .then(({ status, data }) => {
          if (status !== 200) return reject(new Error('getProducts didn\'t return status 200'));
          return resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }),
  });
}

import { filterProducts } from 'helpers/utils/Inventory';

export function getSearchProducts(value) {
  return (dispatch, getState) => dispatch({
    type: GET_SEARCH_PRODUCTS,
    payload: (() => {
      const searchIndex = getState().getIn(['app', 'searchIndex']);
      if (searchIndex === null) return [];
      if (value.trim().length === 0) return [];

      const results = searchIndex.search(value.trim().split(' ').map((term) => `+*${term}*`).join(' '));

      const products = getState().getIn(['app', 'products']).toJS();
      const inventory = getState().getIn(['app', 'inventory']).toJS();

      const filteredProducts = results.map((item) => products.find((product) => item.ref === product._id.baseSku));

      // Remove out of stock items
      const availableProducts = filterProducts(filteredProducts, inventory);

      return availableProducts;
    })(),
  });
}


export function getStores() {
  return (dispatch) => dispatch({
    type: GET_STORES,
    payload: new Promise((resolve, reject) => {
      Api.get('stores')
        .then(({ status, data }) => {
          if (status !== 200) return reject(new Error('getStores didn\'t return status 200'));
          return resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }),
  });
}

export function getInventory(storeId) {
  return (dispatch) => dispatch({
    type: GET_INVENTORY,
    payload: new Promise((resolve, reject) => {
      Api.get(`inventory?storeID=${storeId}`)
        .then(({ status, data }) => {
          if (status !== 200) return reject(new Error('getInventory didn\'t return status 200'));
          return resolve(data.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) window.location.href = loginRoute;
          reject(error);
        });
    }),
  });
}

// export function getConfig(storeId) {
//   return (dispatch) => dispatch({
//     type: GET_STORE_ADMIN,
//     payload: new Promise((resolve, reject) => {
//       Api.get(`admin/storeadmin?store=${storeId}`)
//         .then(({ data }) => resolve({ data }))
//         .catch((error) => reject(error));
//     }),
//   });
// }

export function getScreenConfig(storeId, screen) {
  return (dispatch) => dispatch({
    type: GET_SCREEN_CONFIG,
    payload: new Promise((resolve, reject) => {
      Api.get(`admin/screenConfig?store=${storeId}`)
        .then(({ data }) => {
          const thisScreenConfig = data.screenConfigs.find((screenConfig) => screenConfig.id === parseInt(screen, 10));
          resolve(thisScreenConfig);
        })
        .catch((error) => reject(error));
    }),
  });
}

export function modifyComparisonProduct(product) {
  return (dispatch, getState) => dispatch({
    type: MODIFY_COMPARISON_PRODUCT,
    payload: (() => {
      if (product === null) return { products: [], showError: false };

      let products = getState().getIn(['app', 'compareProducts']).toJS();
      let showError = false;
      const baseSkus = products.map((p) => p.productData.baseSku);

      if (baseSkus.includes(product.productData.baseSku)) {
        products = products.filter((p) => p.productData.baseSku !== product.productData.baseSku);
      } else if (products.length === 4) showError = true;
      else if (products.length < 4) products = [...products, product];

      return { products, showError };
    })(),
  });
}

export function toggleComparePane(show) {
  return (dispatch) => dispatch({
    type: TOGGLE_COMPARE_PANE,
    payload: show,
  });
}

export function toggleShowError(show) {
  return (dispatch) => dispatch({
    type: TOGGLE_SHOW_ERROR,
    payload: show,
  });
}

export function setComparePaneOffset(offset) {
  return (dispatch) => dispatch({
    type: SET_COMPARE_PANE_OFFSET,
    payload: offset,
  });
}

export function getAppConfig() {
  return (dispatch) => dispatch({
    type: GET_APP_CONFIG,
    payload: new Promise((resolve, reject) => {
      CraftApi.post('', { query: `query {
        entry(section: "appConfiguration") {
          ... on appConfiguration_appConfiguration_Entry {
            maxProductQuantity
            maxComparedProducts
            maxGramsPerOrder
            footerCopy {
              ... on footerCopy_BlockType {
                lineOfText
              }
            }
            orderIdModalHeader
            orderIdModalBody
            orderIdModalTitle
            terpenes {
              ... on terpenes_BlockType {
                terpeneName
                color
                description
              }
            }
          }
        }
      }` })
        .then(({ data }) => {
          resolve(data.data.entry);
        })
        .catch((error) => reject(error));
    }),
  });
}

export function getNavConfig() {
  return (dispatch) => dispatch({
    type: GET_NAV_CONFIG,
    payload: new Promise((resolve, reject) => {
      CraftApi.post('', { query: `query {
        entry(section: "navigation") {
          ... on navigation_navigation_Entry {
            menuConfiguration {
              ... on menuConfiguration_primaryMenu_BlockType {
                primaryLabel
                image
                description
                secondaryMenus {
                  ... on secondaryMenus_BlockType {
                    label
                    image
                    description
                    menuType
                    staticPageUrl
                    products {
                      ... on products_skusQuery_BlockType {
                        skus
                      }
                      ... on products_productQuery_BlockType {
                        category
                        subCategory
                        class_flower
                        class_preRoll
                        class_seeds
                        class_edibles
                        class_extractsAndConcentrates
                        class_preparation
                        class_vaporizers
                        class_bongsAndPipes
                        class_storage
                        class_cleaningSupplies
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }` })
        .then(({ data }) => {
          let menuObj = {};
          const menus = data.data.entry.menuConfiguration.map((menuGroup) => {
            const { primaryLabel, image, description, secondaryMenus } = menuGroup;
            let queries = [];
            const subMenus = secondaryMenus.map((subMenu) => {
              const { label, image: subMenuImage, description: subMenuDescription, staticPageUrl, menuType, products } = subMenu;
              let request = [];
              const splitBySlugLabel = (value) => ({
                slug: value.split(':')[0],
                label: value.split(':')[1],
              });
              switch (menuType) {
                case 'product':
                  request = products.map((query) => {
                    const { category, subCategory, skus } = query;
                    if (skus) {
                      return query;
                    }
                    const classes = query[`class_${splitBySlugLabel(subCategory).slug}`] || [];
                    return {
                      category: splitBySlugLabel(category),
                      subCategory: splitBySlugLabel(subCategory),
                      classes: classes.map((value) => splitBySlugLabel(value)),
                    };
                  });
                  queries = queries.concat(request);
                  break;
                case 'allProducts':
                  request = queries;
                  break;
                case 'staticPage':
                  request = [];
                  break;
                default:
                  break;
              }

              return {
                label,
                image: subMenuImage,
                description: subMenuDescription,
                type: menuType,
                staticPageUrl,
                request,
              };
            });

            menuObj = {
              primaryLabel,
              image,
              description,
              subMenus,
            };
            return menuObj;
          });
          resolve(menus);
        })
        .catch((error) => reject(error));
    }),
  });
}

export function setCurrentShopMenu(currentShopMenu) {
  return (dispatch) => dispatch({
    type: SET_CURRENT_SHOP_MENU,
    payload: currentShopMenu,
  });
}

export function setBaseProductFilters(baseProductFilters) {
  return (dispatch) => dispatch({
    type: SET_BASE_PRODUCT_FILTERS,
    payload: baseProductFilters,
  });
}

export function setProductFilters(productFilters) {
  return (dispatch) => dispatch({
    type: SET_PRODUCT_FILTERS,
    payload: productFilters,
  });
}

export function setLastFilterSection(lastFilterSection) {
  return (dispatch) => dispatch({
    type: SET_LAST_FILTER_SECTION,
    payload: lastFilterSection,
  });
}
